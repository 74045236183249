import * as React from 'react';
import SearchTaxPayerForm from './SearchTaxPayerForm';
import { Link } from 'react-router-dom';

const Home = () => (
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12">
                <h1>La Lista Negra del SAT</h1>
                <p>Bienvenido a La Lista Negra del SAT:</p>
                <p>Aqu&iacute; podr&aacute;s consultar si uno de tus proveedores est&aacute; se encuentra en la lista y en que estatus</p>
                <p>M&aacute;s informaci&oacute;n sobre que es la lista negra: <Link to="/faq">&iquest;Qu&eacute; son las listas negras del SAT?</Link></p>
            </div>
            <SearchTaxPayerForm  />
            <div className="col-md-12 col-lg-12 col-sm-12">
                <p>Este portal se actualiza a diario con la informaci&oacute;n oficial publicada por el SAT</p>
                <p><strong>Este portal permite realizar búsquedas ilimitadas. En caso de falla, <Link to="/contact">env&iacute;anos un mensaje</Link></strong></p>
                <p><strong>Si necesitas realizar validar de manera masiva o conocer m&aacute;s sobre las posibilidades del servicio por favor <Link to="/contact">env&iacute;anos un mensaje</Link></strong></p>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12">
                <p>Si este sitio te resulta de utilidad y deseas donar para su mantenimiento, puedes usar el siguiente link de paypal: <a href="https://paypal.me/lvcios?country.x=MX&locale.x=es_XC">paypal.me/lvcios</a></p>
            </div>
        </div>
    </div>
);

export default Home;